import { Button, useTheme, Link, Icon, Paragraph } from '@sixfold/common-ui';
import { Localized } from '@sixfold/localization-component';
import { isNil } from '@sixfold/typed-primitives';
import classnames from 'classnames';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, useLocation } from 'react-router-dom';

import { PRODUCT_NAME } from '../../../constants';
import { Header } from '../../components/header';
import { TrackingContext } from '../../lib/analytics';
import * as Routes from '../../lib/routes';
import { navigateToLocationWithServerSideRendering } from '../../lib/util';

import styles from './TransporeonSignup.module.css';

export const TransporeonSignup: React.FC = () => {
  const { setTracking } = React.useContext(TrackingContext);
  const [isNavigating, setIsNavigating] = React.useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const redirectUrl = searchParams.get('redirectUrl');

  const { variables } = useTheme();

  if (isNil(redirectUrl)) {
    return <Redirect to={`${Routes.LandingPage.routerPath}?error=MISSING_REDIRECT_URL`} />;
  }

  return (
    <>
      <Localized
        id="transporeonSignup.page.title"
        defaultValue="{PRODUCT_NAME} - Real-Time Visibility setup"
        variables={{ PRODUCT_NAME }}>
        {(title) => (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
      </Localized>
      <Header />
      <div className={styles.container}>
        <div className={styles.contentContainer}>
          <div className={styles.content}>
            <h1>
              <Localized id="transporeonSignup.dialog.title">Real-time visibility for your transports</Localized>
            </h1>
            <ul className={styles.sellingPointsList}>
              <li>
                <Icon
                  name="small-check"
                  color={variables?.color.highlight.graphic.default}
                  size={22}
                  className={styles.checkmark}
                />
                <Localized id="transporeonSignup.sellingPoints.automaticVisibility">
                  Provide automatic visibility for just the right transports
                </Localized>
              </li>
              <li>
                <Icon
                  name="small-check"
                  color={variables?.color.highlight.graphic.default}
                  size={22}
                  className={styles.checkmark}
                />
                <Localized id="transporeonSignup.sellingPoints.dataControl">
                  You are 100% in control of your data
                </Localized>
              </li>
              <li>
                <Icon
                  name="small-check"
                  color={variables?.color.highlight.graphic.default}
                  size={22}
                  className={styles.checkmark}
                />
                <Localized id="transporeonSignup.sellingPoints.noMoreCheckCalls">
                  No more check-calls for status updates
                </Localized>
              </li>
              <li>
                <Icon
                  name="small-check"
                  color={variables?.color.highlight.graphic.default}
                  size={22}
                  className={styles.checkmark}
                />
                <Localized id="transporeonSignup.sellingPoints.subcarriersOverview">
                  Get an overview of your subcarriers
                </Localized>
              </li>
            </ul>

            <h3>
              <Localized id="transporeonSignup.processSteps.title">It only takes 5 minutes</Localized>
            </h3>

            <ol className={styles.stepsList}>
              <li>
                <Localized id="transporeonSignup.processSteps.connectVehicles">
                  Connect your vehicles (or invite your subcarriers) to {{ PRODUCT_NAME }}
                </Localized>
              </li>
              <li>
                <Localized id="transporeonSignup.processSteps.readyToProvideVisibility">
                  That's it! You're ready to provide visibility
                </Localized>
              </li>
            </ol>

            <div className={styles.footer}>
              <Paragraph size="small">
                <Localized id="transporeonSignup.dialog.tosAgree">
                  By clicking this button, you agree to the{' '}
                  <Link weight="bold" href="https://www.sixfold.com/terms" target="_blank" rel="noopener">
                    Terms of Service
                  </Link>{' '}
                  and{' '}
                  <Link weight="bold" href="https://sixfold.com/privacy" target="_blank" rel="noopener">
                    Privacy Policy{' '}
                  </Link>
                  of {{ PRODUCT_NAME }}
                </Localized>
              </Paragraph>
              <Button
                fullWidth
                loading={isNavigating}
                size="medium"
                kind="primary"
                onClick={() => {
                  setTracking(true);
                  setIsNavigating(true);
                  navigateToLocationWithServerSideRendering(redirectUrl);
                }}>
                <Localized id="transporeonSignup.dialog.signup">I agree to sign up to {{ PRODUCT_NAME }}</Localized>
              </Button>
            </div>
          </div>
        </div>

        <div className={styles.illustration}>
          <div className={styles.visibilityContainer}>
            <div className={classnames(styles.floatingPillContainer, styles.approachingFirstStop)}>
              <div className={classnames(styles.pill, styles.eventPill)}>
                <div>
                  <Localized id="transporeonSignup.visibilityIllustration.approachingFirstStop">
                    Approaching the first stop
                  </Localized>
                </div>
                <div>
                  <Localized id="transporeonSignup.visibilityIllustration.vehicleBecomesVisible">
                    The vehicle becomes visible
                  </Localized>
                </div>
              </div>
            </div>
            <div className={classnames(styles.floatingPillContainer, styles.firstLoadingStop)}>
              <div className={classnames(styles.pill, styles.stopPill)}>
                <Localized id="transporeonSignup.visibilityIllustration.firstLoadingStop">First loading stop</Localized>
              </div>
            </div>

            <div className={classnames(styles.floatingPillContainer, styles.visibleDuringTransport)}>
              <div className={classnames(styles.pill, styles.eventPill)}>
                <div>
                  <Localized id="transporeonSignup.visibilityIllustration.duringTransport">
                    During the transport
                  </Localized>
                </div>
                <div>
                  <Localized id="transporeonSignup.visibilityIllustration.vehicleVisible">
                    The vehicle is visible
                  </Localized>
                </div>
              </div>
            </div>

            <div className={classnames(styles.floatingPillContainer, styles.lastUnloadingStop)}>
              <div className={classnames(styles.pill, styles.stopPill)}>
                <Localized id="transporeonSignup.visibilityIllustration.lastUnloadingStop">
                  Last unloading stop
                </Localized>
              </div>
            </div>

            <div className={classnames(styles.floatingPillContainer, styles.afterLastStop)}>
              <div className={classnames(styles.pill, styles.eventPill)}>
                <div>
                  <Localized id="transporeonSignup.visibilityIllustration.afterLeavingLastStop">
                    After leaving the last stop
                  </Localized>
                </div>
                <div>
                  <Localized id="transporeonSignup.visibilityIllustration.vehicleNoLongerVisible">
                    The vehicle is no longer visible
                  </Localized>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
