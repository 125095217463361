import { Button, SpinnerOverlay } from '@sixfold/common-ui';
import { Localized } from '@sixfold/localization-component';
import { isNil, notNil } from '@sixfold/typed-primitives';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, useLocation } from 'react-router-dom';

import { PRODUCT_NAME } from '../../../constants';
import { CompanyPicker } from '../../components/company_picker';
import { MapPageFrame } from '../../components/map_page_frame';
import * as Routes from '../../lib/routes';
import { useTokenData } from '../../lib/token';
import { buildRedirectSearchParams, navigateToLocationWithServerSideRendering } from '../../lib/util';

import styles from './Token.module.css';

interface Company {
  company_id: string;
  company_name: string;
  is_shipper: boolean;
  is_carrier: boolean;
}

interface UserLoginToken {
  resourceType: 'USER_LOGIN';
  companies: Company[];
}

export const TokenContainer: React.FC = () => {
  const [isNavigating, setIsNavigating] = React.useState(false);
  const [selectedCompany, setSelectedCompany] = React.useState<Company | undefined>(undefined);

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const token = query.get('token') ?? undefined;
  const targetUrl = query.get('next') ?? undefined;
  const referralToken = query.get('referral_token');

  const { data: companyData, isLoading } = useTokenData<UserLoginToken>(token);

  React.useEffect(() => {
    if (isLoading) {
      return;
    }

    if (companyData && companyData.companies.length > 0) {
      setSelectedCompany(companyData.companies[0]);
    }
  }, [companyData, isLoading]);

  if (isNil(token)) {
    return <Redirect to={`${Routes.LandingPage.routerPath}?error=MISSING_TOKEN`} />;
  }

  if (isLoading) {
    return <SpinnerOverlay />;
  }

  if (isNil(companyData)) {
    return <Redirect to={`${Routes.LandingPage.routerPath}?error=MISSING_COMPANY_DATA`} />;
  }

  const companies = companyData?.companies ?? [];
  const showCompanyPicker = companies.length > 1;

  const renderNavigateButton = () => (
    <Button
      loading={isNavigating}
      size="medium"
      kind="primary"
      onClick={() => {
        setIsNavigating(true);
        const loginTokenRoute = Routes.LoginToken.generatePath({ token });
        const searchParams = buildRedirectSearchParams(targetUrl);

        if (notNil(selectedCompany)) {
          searchParams.set('company_id', selectedCompany.company_id);
        }

        if (notNil(referralToken)) {
          searchParams.set('referral_token', referralToken);
        }

        navigateToLocationWithServerSideRendering(`${loginTokenRoute}?${searchParams.toString()}`);
      }}>
      <Localized id="company.picker.button.navigateToApp">
        Navigate to <b>{{ PRODUCT_NAME }}</b>
      </Localized>
    </Button>
  );

  return (
    <>
      <Localized id="token.page.title" defaultValue="Navigate to {PRODUCT_NAME}" variables={{ PRODUCT_NAME }}>
        {(title) => (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
      </Localized>
      <MapPageFrame className={{ content: showCompanyPicker ? undefined : styles.content }}>
        {showCompanyPicker ? (
          <CompanyPicker
            companies={companies}
            selectedCompany={selectedCompany}
            onChange={(company) => setSelectedCompany(company)}>
            {renderNavigateButton()}
          </CompanyPicker>
        ) : (
          renderNavigateButton()
        )}
      </MapPageFrame>
    </>
  );
};
