import { Alert, Button, Input, Stack } from '@sixfold/common-ui';
import { Form as FormFactory } from '@sixfold/form-component';
import { Localized, useLocalization } from '@sixfold/localization-component';
import React from 'react';

import { PRODUCT_NAME } from '../../../../constants';
import { MapPageFrame } from '../../map_page_frame';

import styles from './Login.module.css';

type Props = {
  isEmailLoginLoading: boolean;
  onSendLoginEmailClick: (email: string) => void;
  showLoginWarning: boolean;
  footer?: React.ReactElement;
};

class EmailLoginForm extends FormFactory<{
  email: string;
}> {}

export const Login: React.FC<Props> = ({ isEmailLoginLoading, onSendLoginEmailClick, showLoginWarning, footer }) => {
  const placeholder = useLocalization('login.email.input.placeholder', 'john.smith@example.com');
  return (
    <MapPageFrame
      showHeader={true}
      title={<Localized defaultValue="Log in to {PRODUCT_NAME}" variables={{ PRODUCT_NAME }} id="login.title" />}
      footer={footer}>
      <Stack direction="column" gap="medium" className={styles.container}>
        {showLoginWarning && (
          <Alert kind="info">
            <Localized id="login.token_error.message">To access {{ PRODUCT_NAME }}, please log in below</Localized>
          </Alert>
        )}
        <Localized id="login.email.input.invalid" defaultValue="Please enter an email address">
          {(invalidEmail) => (
            <EmailLoginForm
              onSubmit={({ email }) => {
                onSendLoginEmailClick(email);
              }}
              validateFields={{ email: { exist: invalidEmail, email: invalidEmail } }}
              initialValues={{ email: '' }}>
              {({ onSubmit, onChange, form, errors }) => (
                <Stack as="form" gap="medium" onSubmit={onSubmit}>
                  <Input
                    label={<Localized id="login.email.input.label" defaultValue="Email address" />}
                    placeholder={placeholder}
                    value={form.email}
                    name="email"
                    autoComplete="email"
                    onChange={({ event }) => onChange(event)}
                    errorMessage={errors.email}
                    invalid={errors.email !== undefined}
                  />
                  <Button fullWidth size="medium" kind="primary" type="submit" loading={isEmailLoginLoading}>
                    <Localized id="login.button.email">Send login link</Localized>
                  </Button>
                </Stack>
              )}
            </EmailLoginForm>
          )}
        </Localized>
      </Stack>
    </MapPageFrame>
  );
};
