import { Radio, Button, Link, RadioGroup, Paragraph, Heading } from '@sixfold/common-ui';
import { Localized } from '@sixfold/localization-component';
import * as React from 'react';

import { PRODUCT_NAME } from '../../../../constants';

import styles from '../Invite.module.css';

export enum LoginType {
  CREATE_TRANSPOREON_ACCOUNT = 'CREATE_TRANSPOREON_ACCOUNT',
  EXISTING_TRANSPOREON_ACCOUNT = 'EXISTING_TRANSPOREON_ACCOUNT',
}

interface Props {
  companyName?: string;
  onNextStep: (selectedLoginType: string | null) => void;
}

export const InviteView: React.FC<Props> = ({ companyName, onNextStep }) => {
  const [loginType, setLoginType] = React.useState<string | null>(null);
  const [isMissingChoice, setIsMissingChoice] = React.useState(true);

  return (
    <>
      <Heading size="medium" type="display">
        <Localized id="invite.welcome.title">Welcome to {{ PRODUCT_NAME }}!</Localized>
      </Heading>

      <Paragraph>
        <Localized id="invite.welcome.joinNetwork">
          You have been asked to join <em>{{ companyName }}</em> carrier network in Transporeon.
        </Localized>
      </Paragraph>

      <Paragraph>
        <Localized id="invite.welcome.introduction">
          In just a few steps you can start providing real-time visibility for the transports you make for them.
        </Localized>
      </Paragraph>

      <div className={styles.selectionGroup}>
        <RadioGroup
          name="invitation_type"
          value={loginType}
          onChange={({ value }) => {
            const isValidType = Object.values(LoginType).find((type) => type === value) === undefined;
            if (isValidType) {
              return;
            }

            setLoginType(value);
            setIsMissingChoice(false);
          }}
          label={
            <Localized id="invite.options.title">Please select one of the following options to get started</Localized>
          }>
          <Radio
            value={LoginType.CREATE_TRANSPOREON_ACCOUNT}
            label={
              <Localized id="invite.loginTypeSelection.createTransporeonAccountSelect">
                Create a Transporeon account
              </Localized>
            }
          />
          <Radio
            value={LoginType.EXISTING_TRANSPOREON_ACCOUNT}
            label={
              <Localized id="invite.loginTypeSelection.existingTransporeonAccountSelect">
                I have a Transporeon account
              </Localized>
            }
          />
        </RadioGroup>
      </div>
      <Button size="medium" kind="primary" disabled={isMissingChoice} onClick={() => onNextStep(loginType)} fullWidth>
        <Localized id="invite.loginTypeSelection.button">Next</Localized>
      </Button>

      <Paragraph>
        <Localized id="invite.support">
          If we can help with anything, please reach out to our{' '}
          <Link href="https://support.transporeon.com/carrieronboarding">support</Link>
        </Localized>
        .
      </Paragraph>
    </>
  );
};
