import { useAnalytics } from '@sixfold/app-analytics-framework';
import { Localized } from '@sixfold/localization-component';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import { PRODUCT_NAME } from '../../../constants';
import { LandingPageContent } from '../../components/login/landing_page/landing_page';
import { buildUrl } from '../../lib/build_url';
import * as Routes from '../../lib/routes';
import { navigateToLocationWithServerSideRendering } from '../../lib/util';

export const LandingPage: React.FC = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const redirectURL = query.get('next');
  const platformID = query.get('platform_id');

  const analytics = useAnalytics();
  const handleTransporeonLoginClick = React.useCallback(() => {
    const transporeonAuthURL = buildUrl(Routes.LoginViaSAML.generatePath({}), [
      { key: 'platform_id', value: platformID },
      { key: 'next', value: redirectURL },
    ]);

    analytics?.track('TransporeonLoginStart');

    navigateToLocationWithServerSideRendering(transporeonAuthURL);
  }, [analytics, platformID, redirectURL]);

  return (
    <>
      <Localized id="landing.page.title" defaultValue="{PRODUCT_NAME} - Landing Page" variables={{ PRODUCT_NAME }}>
        {(title) => (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
      </Localized>
      <LandingPageContent onTransporeonLoginClick={handleTransporeonLoginClick} />
    </>
  );
};
