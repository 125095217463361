import { Heading, Paragraph, Stack } from '@sixfold/common-ui';
import { Localized } from '@sixfold/localization-component';
import { getQueryParameters } from '@sixfold/query-string';
import * as React from 'react';
import { Helmet } from 'react-helmet';

import { PRODUCT_NAME } from '../../../constants';
import { Header } from '../../components/header';

import styles from './Invite.module.css';

export const ExpiredInvite: React.FC = () => {
  const companyName = getQueryParameters(location.search).companyName;
  return (
    <>
      <Localized
        id="expired_invite.page.title"
        defaultValue="{PRODUCT_NAME} - Expired invite"
        variables={{ PRODUCT_NAME }}>
        {(title) => (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
      </Localized>
      <Header />
      <div className={styles.container}>
        <Stack as="main" direction="column" wrap={false} gap="medium">
          <Heading size="medium" type="display">
            <Localized id="expired_invite.page.invite.title">Invite expired!</Localized>
          </Heading>
          <Paragraph>
            <Localized id="expired_invite.page.invite.not_valid">
              The invitation link from <em>{{ companyName }}</em> is no longer valid.
            </Localized>
          </Paragraph>
          <Paragraph>
            <Localized id="expired_invite.page.invite.guidelines">
              Please ask your customer to send you a new invitation, and follow the guidelines described in the email
              you will receive.
            </Localized>
          </Paragraph>
          <Paragraph>
            <Localized id="expired_invite.page.invite.from">Your friends at {{ PRODUCT_NAME }}.</Localized>
          </Paragraph>
        </Stack>
      </div>
    </>
  );
};
