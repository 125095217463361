import { Link } from '@sixfold/common-ui';
import { Localized } from '@sixfold/localization-component';
import { exhaustiveCheck } from '@sixfold/typed-primitives';
import classnames from 'classnames';
import * as React from 'react';

import { ErrorPageFrame } from './error_page_frame';
import { PRODUCT_NAME } from '../../../constants';
import { BackendError } from '../../../shared/types/error';

import styles from './ErrorView.module.css';

interface Props {
  error:
    | { type: ErrorType.GENERIC_ERROR; message?: string }
    | { type: ErrorType.USER_NOT_FOUND; email?: string }
    | { type: ErrorType.PAGE_NOT_FOUND }
    | { type: BackendError };
}

export enum ErrorType {
  USER_NOT_FOUND,
  GENERIC_ERROR,
  PAGE_NOT_FOUND,
}

export const ErrorView: React.FC<Props> = ({ error }) => {
  const { type } = error;
  switch (type) {
    case ErrorType.USER_NOT_FOUND: {
      const email = error.email;

      return (
        <ErrorPageFrame
          title={
            <Localized id="login.error.userNotFound.title">You do not have a {{ PRODUCT_NAME }} account</Localized>
          }
          loginButtonText={
            <Localized id="login.error.userNotFound.button.logIn">Log in with a different email</Localized>
          }>
          <span className={styles.text}>
            <Localized id="login.error.userNotFound.askHelp">
              If your company is already on {{ PRODUCT_NAME }}, ask one of your colleagues to invite you or{' '}
              <Link href="https://support.transporeon.com" target="_blank" rel="noopener noreferrer nofollow">
                contact our support
              </Link>{' '}
              to assist you.
            </Localized>
          </span>
          <span className={classnames(styles.small, styles.text)}>
            <Localized id="login.error.userNotFound.tryLogin">
              If you made a typo or used the wrong email (<b>{{ email }}</b>), you can try to log in again.
            </Localized>
          </span>
        </ErrorPageFrame>
      );
    }
    case BackendError.COMPANY_ALREADY_EXISTS: {
      return (
        <ErrorPageFrame
          title={
            <Localized id="signup.error.companyExists.title.fallback">
              This company is already registered on {{ PRODUCT_NAME }}
            </Localized>
          }
          loginButtonText={
            <Localized id="signup.error.companyExists.button.logIn">Log in to {{ PRODUCT_NAME }}</Localized>
          }>
          <span className={styles.text}>
            <Localized id="signup.error.companyExists.askHelp">
              Try logging in or asking your colleagues to give you access to {{ PRODUCT_NAME }}.
            </Localized>
          </span>
        </ErrorPageFrame>
      );
    }
    case BackendError.COMPANY:
    case BackendError.TOKEN:
    case ErrorType.GENERIC_ERROR: {
      return (
        <ErrorPageFrame
          title={<Localized id="component.formatted_error.error.title">Something went wrong</Localized>}
          loginButtonText={<Localized id="redirect.button.home">Back to home page</Localized>}
        />
      );
    }

    case ErrorType.PAGE_NOT_FOUND: {
      return (
        <ErrorPageFrame
          title={<Localized id="component.formatted_error.pagenotfound">Page not found</Localized>}
          loginButtonText={<Localized id="redirect.button.home">Back to home page</Localized>}
        />
      );
    }

    default:
      exhaustiveCheck(type);
  }
};
