import { Button, Stack } from '@sixfold/common-ui';
import { Localized } from '@sixfold/localization-component';
import * as React from 'react';

import { Footer } from '../../footer/footer';
import { MapPageFrame } from '../../map_page_frame';

type Props = {
  onTransporeonLoginClick: () => void;
};

export const LandingPageContent: React.FC<Props> = ({ onTransporeonLoginClick }) => {
  const footer = React.useMemo(() => <Footer />, []);

  return (
    <MapPageFrame showHeader={true} footer={footer} showLoginLink>
      <Stack direction="column" gap="medium">
        <Button fullWidth kind="primary" onClick={onTransporeonLoginClick}>
          <Localized id="login.button.transporeon">
            Log in with <span>Transporeon</span>
          </Localized>
        </Button>
      </Stack>
    </MapPageFrame>
  );
};
