import * as React from 'react';

import { EmbedConfig, EmbedData } from '../../shared/types';

export const EmbedDataContext = React.createContext<{
  embedConfig: EmbedConfig | null;
  embedData: EmbedData;
}>({
  embedConfig: null,
  embedData: {},
});

export const EmbedDataProvider = EmbedDataContext.Provider;
