import { useAnalytics } from '@sixfold/app-analytics-framework';
import { SpinnerOverlay } from '@sixfold/common-ui';
import { Localized } from '@sixfold/localization-component';
import { NestedError, notNil } from '@sixfold/typed-primitives';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import { PRODUCT_NAME } from '../../../constants';
import { LoginView } from '../../components/login';
import { buildUrl } from '../../lib/build_url';
import { EmbedDataContext } from '../../lib/embed_data_context';
import { RememberLoginDetails } from '../../lib/remember_login_types';
import * as Routes from '../../lib/routes';
import { fetchJSON, navigateToLocationWithServerSideRendering } from '../../lib/util';

const useRememberLoginCookieValue = () => {
  const [data, setData] = React.useState<RememberLoginDetails | null>(null);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchJSON<RememberLoginDetails>(Routes.RememberLoginCookie.routerPath);
        setData(response);
      } catch (error) {
        throw new NestedError('Failed to fetch remember login cookie data', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData().catch((e) => console.error(e));
  }, []);

  return { data, isLoading };
};

export const LoginPage = () => {
  const analytics = useAnalytics();

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const { embedConfig } = React.useContext(EmbedDataContext);
  const customerWebUrl = embedConfig?.customer_web_url;

  const platformId = params.get('platform_id');
  const redirectURL = params.get('next');
  const showLoginWarning = notNil(params.get('error_type'));

  const { data: rememberLoginDetails, isLoading } = useRememberLoginCookieValue();

  const handleTransporeonLoginClick = React.useCallback(() => {
    const transporeonAuthURL = buildUrl(Routes.LoginViaSAML.generatePath({}), [
      { key: 'platform_id', value: platformId },
      { key: 'next', value: redirectURL },
    ]);

    analytics?.track('TransporeonLoginStart');

    navigateToLocationWithServerSideRendering(transporeonAuthURL);
  }, [analytics, platformId, redirectURL]);

  const createRedirectUri = () => {
    const redirectAfterLogin = redirectURL ?? customerWebUrl;

    if (redirectAfterLogin === undefined) {
      return;
    }

    return redirectAfterLogin;
  };

  if (isLoading) {
    return <SpinnerOverlay />;
  }

  return (
    <>
      <Localized id="login.page.title" defaultValue="{PRODUCT_NAME} - Login" variables={{ PRODUCT_NAME }}>
        {(title) => (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
      </Localized>
      <LoginView
        redirectURI={createRedirectUri()}
        onTransporeonLoginClick={handleTransporeonLoginClick}
        showLoginWarning={showLoginWarning}
        rememberLoginDetails={rememberLoginDetails ?? undefined}
      />
    </>
  );
};
