import { Heading, Paragraph, Stack } from '@sixfold/common-ui';
import { Localized } from '@sixfold/localization-component';
import { getQueryParameters } from '@sixfold/query-string';
import * as React from 'react';
import { Helmet } from 'react-helmet';

import { PRODUCT_NAME } from '../../../constants';
import { Header } from '../../components/header';

import styles from './Withdrawal.module.css';

export const WithdrawnInvite: React.FC = () => {
  const queryString = getQueryParameters(location.search);
  const companyName = queryString.companyName;

  return (
    <>
      <Localized
        id="withdrawn_invite.page.title"
        defaultValue="{PRODUCT_NAME} - Withdrawn invite"
        variables={{ PRODUCT_NAME }}>
        {(title) => (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
      </Localized>
      <Header />
      <Stack alignItems="center" justifyContent="center" className={styles.container}>
        <Stack as="main" direction="column" wrap={false} gap="medium">
          <Heading size="medium" type="display">
            <Localized id="withdrawn_invite.page.message.title">Oops! The invite link is no longer valid.</Localized>
          </Heading>
          <Paragraph>
            <Localized id="withdrawn_invite.page.message.guidelines">
              Please contact {{ companyName }} directly for assistance. They can explain why the link is no longer valid
              or provide you with an updated invite link to resume your onboarding process. We apologize for any
              inconvenience this may cause.
            </Localized>
          </Paragraph>
          <Paragraph>
            <Localized id="withdrawn_invite.page.message.from">Your friends at {{ PRODUCT_NAME }}.</Localized>
          </Paragraph>
        </Stack>
      </Stack>
    </>
  );
};
