import { useAnalytics } from '@sixfold/app-analytics-framework';
import { SpinnerOverlay } from '@sixfold/common-ui';
import { Localized } from '@sixfold/localization-component';
import { isNil, notNil } from '@sixfold/typed-primitives';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import { PRODUCT_NAME } from '../../../constants';
import { BackendError } from '../../../shared/types/error';
import { ErrorType, ErrorView } from '../../components/error_view';
import { navigateToLocationWithServerSideRendering } from '../../lib/util';

import styles from './redirect.module.css';

export const RedirectContainer: React.FC = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const errorType = query.get('error_type');
  const redirectUrl = query.get('redirectUrl');
  const userId = query.get('userId'); // signup specific – used for analytics
  const timeout = notNil(query.get('timeout')) ? parseInt(query.get('timeout') as string, 10) : 500;

  const analytics = useAnalytics();

  const getErrorObject = (errorType: string): ErrorType | BackendError => {
    switch (errorType) {
      case 'COMPANY_ALREADY_EXISTS':
        return BackendError.COMPANY_ALREADY_EXISTS;
      case 'USER_NOT_FOUND':
        return ErrorType.USER_NOT_FOUND;
      case 'PAGE_NOT_FOUND':
        return ErrorType.PAGE_NOT_FOUND;
      case 'TOKEN':
        return BackendError.TOKEN;
      case 'COMPANY':
        return BackendError.COMPANY;
      default:
        return ErrorType.GENERIC_ERROR;
    }
  };

  React.useEffect(() => {
    if (notNil(userId)) {
      try {
        analytics?.identify(userId);
      } catch (error) {
        console.error(error);
      }
    } else {
      console.log('Skipping identification, no user ID');
    }

    let timer: number | undefined;
    if (isNil(errorType) && notNil(redirectUrl)) {
      timer = window.setTimeout(() => {
        navigateToLocationWithServerSideRendering(redirectUrl);
      }, timeout);
    }
    return () => window.clearTimeout(timer);
  }, [errorType, timeout, userId, redirectUrl, analytics]);

  return (
    <>
      <Localized id="redirect.page.title" defaultValue="{PRODUCT_NAME} - Redirecting..." variables={{ PRODUCT_NAME }}>
        {(title) => (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
      </Localized>
      {notNil(errorType) && errorType !== '' ? (
        <ErrorView
          error={{
            type: getErrorObject(errorType),
          }}
        />
      ) : (
        <SpinnerOverlay className={styles.loading} />
      )}
    </>
  );
};
