import { Link, Text } from '@sixfold/common-ui';
import { Localized, useLocalizations } from '@sixfold/localization-component';
import { isNil, notNil } from '@sixfold/typed-primitives';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import urlTemplate from 'url-template';

import { PRODUCT_NAME } from '../../../constants';
import { Header } from '../../components/header';
import { EmbedDataContext } from '../../lib/embed_data_context';

import styles from './EmailUnsubscribe.module.css';

export const EmailUnsubscribe: React.FC = () => {
  const { embedConfig } = React.useContext(EmbedDataContext);

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const type = params.get('type');
  const companyName = params.get('companyName');
  const companyId = params.get('companyId');

  const dictionary = useLocalizations({
    'email_unsubscribe.page.body.type.global_allocation_tour_forwarded':
      'License plate allocated for limited visibility transport',
    'email_unsubscribe.page.body.type.tour_forwarded': 'New transport forwarded',
  });

  const getTypeName = React.useCallback(
    (type: string | null) => {
      switch (type) {
        case 'GLOBAL_ALLOCATION_TOUR_FORWARDED':
          return dictionary['email_unsubscribe.page.body.type.global_allocation_tour_forwarded'];
        case 'TOUR_FORWARDED':
          return dictionary['email_unsubscribe.page.body.type.tour_forwarded'];
        default:
          return null;
      }
    },
    [dictionary],
  );

  if (isNil(embedConfig) || isNil(embedConfig.customer_web_email_notifications_manage_url_template)) {
    return null;
  }

  const manageUrl = urlTemplate.parse(embedConfig?.customer_web_email_notifications_manage_url_template).expand({
    company: companyId,
  });

  const typeName = getTypeName(type);

  return (
    <>
      <Localized
        id="email_unsubscribe.page.title"
        defaultValue="{PRODUCT_NAME} - Email unsubscribe"
        variables={{ PRODUCT_NAME }}>
        {(title) => (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
      </Localized>
      <Header />
      <div className={styles.container}>
        <main>
          {notNil(type) ? (
            <>
              <h1>
                <Localized id="email_unsubscribe.page.title.unsubscribed">You have unsubscribed</Localized>
              </h1>
              <Text>
                {notNil(typeName) ? (
                  <Localized id="email_unsubscribe.page.body.unsubscribed_known_type">
                    You're no longer receiving emails from Transporeon about the topic: <em>{{ type: typeName }}</em>
                  </Localized>
                ) : (
                  <Localized id="email_unsubscribe.page.body.unsubscribed_unknown_type">
                    You will no longer receive these emails from Transporeon.
                  </Localized>
                )}
              </Text>
              <Link href={manageUrl} target="_blank" rel="noopener noreferrer nofollow">
                <Localized id="email_unsubscribe.page.body.manage_notifications">
                  Manage other email preferences
                </Localized>
              </Link>
              <Text prominence="subtle">
                <Localized id="email_unsubscribe.page.body.applies_to_company">
                  This applies to {{ companyName }}
                </Localized>
              </Text>
            </>
          ) : (
            <>
              <h1>
                <Localized id="email_unsubscribe.page.title.link_expired">Your link has expired</Localized>
              </h1>
              <Text>
                <Localized id="email_unsubscribe.page.body.unsubscribed">
                  Your unsubscribe link has expired. Check for a working link in a recent email.
                </Localized>
              </Text>
            </>
          )}
        </main>
      </div>
    </>
  );
};
