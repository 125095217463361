import { AnalyticsProvider, ErrorHandlerProvider, useLocalStorage } from '@sixfold/app-framework';
import { LocalizationDictionary, LocalizationProvider, ThemeObject, ThemeProvider } from '@sixfold/common-ui';
import { transporeonLightClassName } from '@sixfold/common-ui/dist/lib/css';
import transporeonLight from '@sixfold/design-tokens/build/transporeon/json/variables.json';
import iconsUrl from '@sixfold/icons/dist/sprite.svg';
import { initialize as initializeI18n, useLocalizations } from '@sixfold/localization-component';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

import { AppRouter } from './app_router';
import { ErrorBoundary } from './components/error_boundary';
import { getEmbedConfig, getEmbedData } from './data';
import { isTrackingCookieAllowed, TrackingProvider } from './lib/analytics';
import { getIsConsentGiven, setConsentCookie } from './lib/cookie_consent';
import { EmbedDataProvider } from './lib/embed_data_context';
import { RouterProvider } from './lib/router_provider';
import * as Routes from './lib/routes';
import '@sixfold/common-ui/dist/lib/styles/global.css';
import '@sixfold/design-tokens/build/transporeon/css/fonts.css';
import '@sixfold/design-tokens/build/transporeon/css/variables.css';
import { sentryOptions } from './sentry';

const themes: Record<string, ThemeObject> = {
  'transporeon-light': {
    className: transporeonLightClassName,
    variables: transporeonLight,
  },
};

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

const useStoredTheme = () => {
  return useLocalStorage<string>('common-ui-theme', 'transporeon-light');
};

// Define the function externally, so its identity would remain the same across rerenders.
const getIconPath = (name: string) => `${iconsUrl}#${name}`;

const embedData = getEmbedData();
const embedConfig = getEmbedConfig();

initializeI18n({
  detectBrowserLanguage: {
    cookieDomain: embedConfig !== null ? embedConfig.cookie_domain : undefined,
  },
  logMissingKeys: false,
  useHttpBackend: {
    loadPath: (lng, ns) => `/locales/${lng}/${ns}?key=${embedData.translations_cache_keys?.[lng[0]] ?? ''}`,
  },
});

export const ThemeWrapper: React.FunctionComponent<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [theme, setTheme] = useStoredTheme();

  return (
    <ThemeProvider currentTheme={theme} themes={themes} onThemeChange={setTheme} getIconPath={getIconPath}>
      {children}
    </ThemeProvider>
  );
};

const AppWrapper: React.FC = () => {
  const [trackingState, setTrackingState] = React.useState<boolean>(isTrackingCookieAllowed());
  const [isAnalyticsConsented] = React.useState<boolean>(getIsConsentGiven());

  const commonUIlocalizations = useLocalizations({
    'common.ui.clear.title': 'Clear',
    'common.ui.close.title': 'Close',
    'common.ui.remove.title': 'Remove',
    'common.ui.sort.ascending.title': 'Sort ascending',
    'common.ui.sort.descending.title': 'Sort descending',
    'common.ui.table.dragHandleLabel': 'Drag and drop to reorder columns.',
    'common.ui.table.selectRow': 'Select',
    'common.ui.table.selectAllRows': 'Select all',
    'common.ui.tree.expandItem': 'Expand',
    'common.ui.toast.progressLine': 'Progress line',
    'common.ui.treeSelect.search': 'Search',
    'common.ui.treeSelect.search.checkAndTryAgain': 'Please check your search keyword and try again.',
    'common.ui.multiSelect.create': 'Create',
    'common.ui.multiSelect.select': 'Select',
    'common.ui.multiselect.noResults.label': 'No results found',
    'common.ui.timePicker.time': 'Time',
    'common.ui.timePicker.minutes': 'Minutes',
    'common.ui.timePicker.hours': 'Hours',
    'common.ui.timePicker.addMinutes': 'Add minutes',
    'common.ui.timePicker.subMinutes': 'Subtract minutes',
    'common.ui.timePicker.addHours': 'Add Hours',
    'common.ui.timePicker.subHours': 'Subtract hours',
    'common.ui.timePicker.apply': 'Apply',
    'common.ui.timePicker.yourSelection': 'Your selection',
  });

  const localizationProviderDictionary: LocalizationDictionary = React.useMemo(
    () => ({
      clear: commonUIlocalizations['common.ui.clear.title'],
      close: commonUIlocalizations['common.ui.close.title'],
      remove: commonUIlocalizations['common.ui.remove.title'],
      sortAscending: commonUIlocalizations['common.ui.sort.ascending.title'],
      sortDescending: commonUIlocalizations['common.ui.sort.descending.title'],
      'table.selectRow': commonUIlocalizations['common.ui.table.selectRow'],
      'table.selectAllRows': commonUIlocalizations['common.ui.table.selectAllRows'],
      'tree.expandItem': commonUIlocalizations['common.ui.tree.expandItem'],
      search: commonUIlocalizations['common.ui.treeSelect.search'],
      progressLine: commonUIlocalizations['common.ui.toast.progressLine'],
      'search.noResults': commonUIlocalizations['common.ui.multiselect.noResults.label'],
      'search.checkAndTryAgain': commonUIlocalizations['common.ui.treeSelect.search.checkAndTryAgain'],
      'multiSelect.create': commonUIlocalizations['common.ui.multiSelect.create'],
      'multiSelect.select': commonUIlocalizations['common.ui.multiSelect.select'],
      'timePicker.time': commonUIlocalizations['common.ui.timePicker.time'],
      'timePicker.minutes': commonUIlocalizations['common.ui.timePicker.minutes'],
      'timePicker.hours': commonUIlocalizations['common.ui.timePicker.hours'],
      'timePicker.addMinutes': commonUIlocalizations['common.ui.timePicker.addMinutes'],
      'timePicker.subMinutes': commonUIlocalizations['common.ui.timePicker.subMinutes'],
      'timePicker.addHours': commonUIlocalizations['common.ui.timePicker.addHours'],
      'timePicker.subHours': commonUIlocalizations['common.ui.timePicker.subHours'],
      'timePicker.apply': commonUIlocalizations['common.ui.timePicker.apply'],
      'timePicker.yourSelection': commonUIlocalizations['common.ui.timePicker.yourSelection'],
      'table.dragHandleLabel': commonUIlocalizations['common.ui.table.dragHandleLabel'],
    }),
    [commonUIlocalizations],
  );

  const routes = Object.values(Routes);

  return (
    <EmbedDataProvider value={{ embedConfig, embedData }}>
      <ErrorHandlerProvider sentryOptions={sentryOptions}>
        <ThemeWrapper>
          <ErrorBoundary>
            <TrackingProvider
              value={{
                isTrackingAllowed: trackingState,
                setTracking: (newState) => {
                  setTrackingState(newState);
                  setConsentCookie(newState);
                  return newState;
                },
              }}>
              <Router>
                <RouterProvider>
                  <LocalizationProvider dictionary={localizationProviderDictionary}>
                    <AnalyticsProvider
                      routes={routes}
                      isTrackingAllowed={isAnalyticsConsented}
                      mixpanelToken={embedConfig?.mixpanel_token}>
                      <AppRouter />
                    </AnalyticsProvider>
                  </LocalizationProvider>
                </RouterProvider>
              </Router>
            </TrackingProvider>
          </ErrorBoundary>
        </ThemeWrapper>
      </ErrorHandlerProvider>
    </EmbedDataProvider>
  );
};
root.render(<AppWrapper />);
