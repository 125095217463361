import { useAnalytics } from '@sixfold/app-analytics-framework';
import { SpinnerOverlay } from '@sixfold/common-ui';
import { Localized } from '@sixfold/localization-component';
import { getQueryParameters } from '@sixfold/query-string';
import { isNil } from '@sixfold/typed-primitives';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, useLocation } from 'react-router-dom';

import { InviteView, LoginType } from './components/InviteView';
import { PRODUCT_NAME } from '../../../constants';
import { Header } from '../../components/header';
import * as Routes from '../../lib/routes';
import { useTokenData } from '../../lib/token';
import { navigateToLocationWithServerSideRendering } from '../../lib/util';

import styles from './Invite.module.css';

interface PlatformCompanyInviteToken {
  resourceType: 'PLATFORM_COMPANY_INVITE';
  companyName: string;
  signupUrls: {
    newRegistrationUrl: string;
    updateRegistrationUrl: string;
  };
}

export const Invite: React.FC = () => {
  const analytics = useAnalytics();

  const query = getQueryParameters(useLocation().search);

  const token = query.token;
  const { data, isLoading } = useTokenData<PlatformCompanyInviteToken>(token);

  if (isLoading) {
    return <SpinnerOverlay />;
  }

  if (isNil(data)) {
    return <Redirect to={`${Routes.LandingPage.routerPath}`} />;
  }

  const companyName = data.companyName;
  const newRegistrationUrl = data.signupUrls.newRegistrationUrl;
  const updateRegistrationUrl = data.signupUrls.updateRegistrationUrl;

  if (isNil(newRegistrationUrl) || isNil(updateRegistrationUrl)) {
    return <Redirect to={`${Routes.LandingPage.routerPath}?error=MISSING_REGISTRATION_URL`} />;
  }

  const getNavigateToUrl = (loginType: string | null) => {
    switch (loginType) {
      case LoginType.CREATE_TRANSPOREON_ACCOUNT:
        return newRegistrationUrl;
      case LoginType.EXISTING_TRANSPOREON_ACCOUNT:
        return updateRegistrationUrl;
      default:
        return newRegistrationUrl;
    }
  };

  return (
    <>
      <Localized id="invite.page.title" defaultValue="{PRODUCT_NAME} - Invite" variables={{ PRODUCT_NAME }}>
        {(title) => (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
      </Localized>
      <Header />
      <div className={styles.container}>
        <main>
          <InviteView
            companyName={companyName}
            onNextStep={(type) => {
              analytics?.track('Invite flow selected', {
                type: (type ?? 'unknown').toString(),
              });
              navigateToLocationWithServerSideRendering(getNavigateToUrl(type));
            }}
          />
        </main>
      </div>
    </>
  );
};
